import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'

import ModalCreateService from '@/components/modals/modalCreateService'

const Card = () => externalComponent('card')
const CardIcon = () => externalComponent('cardIcon')
const CardButton = () => externalComponent('cardButton')
const Toolbar = () => externalComponent('toolbar')
const Table = () => externalComponent('table')
const Pagination = () => externalComponent('pagination')
const FormButton = () => externalComponent('formButton')

const utils = require('./../../../utils')

export default {
  name: 'Servicios',

  components: {
    Card,
    CardIcon,
    CardButton,
    Toolbar,
    Table,
    Pagination,
    ModalCreateService,
    FormButton
  },

  data() {
    return {
      info: {
        title: 'Servicio Extra',
        status: {},
        start_date: '',
        end_date: ''
      },
      ids: [],
      timeline: {
        selected: [],
        rows: [],
        pages: 0,
        currentPage: 1,
        section: 'charges',
        action: {
          name: 'Acciones',
          options: [{
              name: 'Activar',
              value: 'changeStatusServiceActive'
            }, {
              name: 'Posponer',
              value: 'changeStatusServiceInactive'
            // }, {
            //   name: 'Editar',
            //   value: 'serviceEdit'
            }, {
              name: 'Eliminar',
              value: 'deleteService'
          }]
        },
        // search: {
        //   name: 'Propiedad',
        //   placeholder: 'Propiedad'
        // },
        mainAction: {
          name: 'Agregar servicio',
          emit: 'showModalCreateService'
        },
        filter: {
          name: 'Estado',
          options: [
            {
              name: 'Aprobada',
              value: 'open'
            },
            {
              name: 'Denegada',
              value: 'close'
            },
            {
              name: 'Revisión',
              value: 'pending'
            },
          ]
        },
        sections: [
          {
            name: 'Cargos',
            value: 'charges'
          },
          {
            name: 'Ordenes de pago',
            value: 'orders'
          }
        ],
        columns: [
          {
            name: 'Tarifa',
            checkbox: true
          },
          {
            name: 'Precio'
          },
          {
            name: 'Estado'
          }
        ]
      },

      isModalCreateService: false,
      isModalCreateService: false,
    }
  },

  methods: {
    getServices: async function() {
      let response;

      await axios
        .get(apiDomain + '/service')
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.timeline.rows = response.data;

        this.timeline.rows.forEach(element => {
          element[1].value = utils.getMoneyFormat(element[1].value);
        });
      } else {
        this.$toast.error('Error en la consulta de la estancia');
      }
    },

    updateService: async function() {
      let response;

      await axios
        .post(apiDomain + '/service')
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.timeline.rows = response.data;
      } else {
        this.$toast.error('Error en la consulta de la estancia');
      }
    },

    deleteService: async function() {
      if( this.timeline.selected.length > 0 ) {
        await axios
        .delete(apiDomain + '/service', { data: this.timeline.selected })
        .then(res => {
          let response = res;

          if (typeof response !== 'undefined' && response.status === 200) {
            this.timeline.rows = response.data;
          } else {
            this.$toast.error('Error en la consulta.');
          }

          this.getServices()
        })
        .catch(err => error = true);
      }
    },

    changeStatusServiceActive: async function() {
      if( !this.timeline.selected.length ) {
        this.$toast.warning('Selecciona las servicios que quieras activar');
        return
      }

      let response;

      await axios
        .post(apiDomain + '/service/changeStatusActive', this.timeline.selected)
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.$toast.success('Servicios Actualizada.');
        this.getServices();
      } else {
        this.$toast.error('Error en la consulta');
      }
    },

    changeStatusServiceInactive: async function() {
      if( !this.timeline.selected.length ) {
        this.$toast.warning('Selecciona las servicios que quieras desactivar');
        return
      }

      let response;

      await axios
        .post(apiDomain + '/service/changeStatusInactive', this.timeline.selected)
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.$toast.success('Servicios Actualizada.');
        this.getServices();
      } else {
        this.$toast.error('Error en la consulta');
      }
    },

    changeStatusServiceInactive___: async function() {
      // console.log(this.timeline.selected);
      return
      let response;

      await axios
        .post(apiDomain + '/service/changeStatusInactive')
        .then(res => response = res)
        .catch(err => response = err);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.timeline.rows = response.data;
      } else {
        this.$toast.error('Error en la consulta de la estancia');
      }
    },

    doAction: function(action) {
      switch (action) {
        case 'deleteService':
          this.deleteService();
          break;
        case 'changeStatusServiceActive':
          this.changeStatusServiceActive();
          break;
        case 'changeStatusServiceInactive':
          this.changeStatusServiceInactive();
          break;
      }
    },

    extra: function(id) {
      // console.log(id);
    },

    showModalCreateService: function() {
      this.isModalCreateService = true;
    },

    hideModalCreateService: function() {
      this.isModalCreateService = false;
      this.getServices();
    }
  },

  mounted() {
    this.getServices();

    EventBus.$on('hideModalCreateService', () => {
      this.hideModalCreateService();
    });
  }
}
